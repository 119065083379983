// @flow
/* eslint import/no-webpack-loader-syntax: 0 */

import React, { Component } from "react";
import {
  AreaHighlight,
  Highlight,
  PdfHighlighter,
  PdfLoader,
  Popup,
  setPdfWorker,
  Tip,
} from "react-pdf-highlighter";
import type {
  T_Highlight,
  T_NewHighlight,
} from "react-pdf-highlighter/src/types";
import PDFWorker from "worker-loader!pdfjs-dist/lib/pdf.worker";
import Spinner from "./Spinner";
import "./style/App.css";
import testHighlights from "./test-highlights";
setPdfWorker(PDFWorker);

type Props = {};

type State = {
  url: string,
  highlights: Array<T_Highlight>,
};

const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
  document.location.hash.slice("#highlight-".length);

const resetHash = () => {
  document.location.hash = "";
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const PRIMARY_PDF_URL = "https://arxiv.org/pdf/1708.08021.pdf";

// const PRIMARY_PDF_URL =
//   "https://isens-middleware-docker.azurewebsites.net/download/resources?filename=Delhi_Metro_Extract_Self.pdf&project_id=54&folder_id=82";

const searchParams = new URLSearchParams(document.location.search);
const initialUrl = searchParams.get("url")
  ? searchParams.get("url") +
    `&project_id=${searchParams.get("project_id")}&folder_id=${searchParams.get(
      "folder_id"
    )}&token=${searchParams.get("token") || ""}`
  : PRIMARY_PDF_URL;
// const initialUrl = PRIMARY_PDF_URL;
class App extends Component<Props, State> {
  state = {
    url: initialUrl,
    highlights: [],
  };

  constructor(props) {
    super(props);
    window.addEventListener(
      "message",
      (e) => {
        if (/^react-devtools/gi.test(e.data.source)) {
          return;
        }
        try {
          if (e.data.content) {
            console.log(e.data);
            // console.log(this.state);
            // console.log(this.addHighlight(e.data));
            const newId = this.addHighlight(e.data);
            this.scrollToHighlightFromHash();
            document.location.hash = `highlight-${newId}`;
          }
        } catch (error) {}
      },
      false
    );
  }

  resetHighlights = () => {
    this.setState({
      highlights: [],
    });
  };

  toggleDocument = () => {
    const newUrl = this.state.url === PRIMARY_PDF_URL;

    this.setState({
      url: newUrl,
      highlights: testHighlights[newUrl] ? [...testHighlights[newUrl]] : [],
    });
  };

  scrollViewerTo = (highlight: any) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  getHighlightById(id: string) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight.id === id);
  }

  addHighlight(highlight: T_NewHighlight) {
    // const { highlights } = this.state;

    console.log("Saving highlight", highlight);

    // this.setState({
    //   highlights: [{ ...highlight, id: getNextId() }, ...highlights],
    // });
    const newId = getNextId();
    this.setState({
      highlights: [{ ...highlight, id: newId }],
    });
    return newId;
  }

  updateHighlight(highlightId: string, position: Object, content: Object) {
    console.log("Updating highlight", highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return id === highlightId
          ? {
              id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }

  componentDidMount() {
    window.addEventListener(
      "hashchange",
      this.scrollToHighlightFromHash,
      false
    );
  }

  render() {
    const { url, highlights } = this.state;

    return (
      <div className="App" style={{ display: "flex", height: "100vh" }}>
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "relative",
          }}
        >
          <PdfLoader url={url} beforeLoad={<Spinner />}>
            {(pdfDocument) => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={(event) => event.altKey}
                onScrollChange={resetHash}
                // pdfScaleValue="page-width"
                scrollRef={(scrollTo) => {
                  this.scrollViewerTo = scrollTo;

                  this.scrollToHighlightFromHash();
                }}
                // onSelectionFinished={(
                //   position,
                //   content,
                //   hideTipAndSelection,
                //   transformSelection
                // ) => (
                //   <Tip
                //     onOpen={transformSelection}
                //     onConfirm={(comment) => {
                //       this.addHighlight({ content, position, comment });

                //       hideTipAndSelection();
                //     }}
                //   />
                // )}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {
                  const isTextHighlight = !Boolean(
                    highlight.content && highlight.content.image
                  );

                  const component = isTextHighlight ? (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                    />
                  ) : (
                    <AreaHighlight
                      isScrolledTo={isScrolledTo}
                      highlight={highlight}
                      onChange={(boundingRect) => {
                        this.updateHighlight(
                          highlight.id,
                          { boundingRect: viewportToScaled(boundingRect) },
                          { image: screenshot(boundingRect) }
                        );
                      }}
                    />
                  );

                  return (
                    <Popup
                      popupContent={<HighlightPopup {...highlight} />}
                      onMouseOver={(popupContent) =>
                        setTip(highlight, (highlight) => popupContent)
                      }
                      onMouseOut={hideTip}
                      key={index}
                      children={component}
                    />
                  );
                }}
                highlights={highlights}
              />
            )}
          </PdfLoader>
        </div>
      </div>
    );
  }
}

export default App;
